<template>
  <v-form @submit.prevent="submitMethod">
    <v-text-field
      v-model="first_name"
      label="First Name"
      autofocus
    />
    <v-text-field
      v-model="last_name"
      label="Last Name"
    />
    <v-text-field
      v-model="email"
      label="Email"
      type="email"
      :rules="[emailRule]"
    />
    <v-text-field
      v-model="phone"
      label="Phone Number"
      type="tel"
      :rules="[phoneRule]"
    />
    <vuetify-google-autocomplete
      id="map"
      label="Physical Address"
      @placechanged="getAddressData"
      :value="address"
    />
    <hidden-submit-button/>
  </v-form>
</template>

<script>
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';
import { emailRegex, phoneRegex } from '@/utils/misc';

export default {
  name: 'ContactEditor',

  components: {
    HiddenSubmitButton,
  },

  props: {
    index: Number,
  },

  data() {
    const customer = this.$store.state.proposals.current.customers[this.index];

    return {
      first_name: customer ? customer.first_name : '',
      last_name: customer ? customer.last_name : '',
      email: customer ? customer.email : '',
      phone: customer ? customer.phone : '',
      address: customer ? customer.address : '',
      geocode_address: null,
    };
  },

  computed: {
    saveBtnEnabled() {
      const customer = this.$store.state.proposals.current.customers[this.index];

      const firstNameChanged = this.first_name !== customer.first_name;
      const lastNameChanged = this.last_name !== customer.last_name;
      const emailChanged = this.email !== customer.email;
      const phoneChanged = this.phone !== customer.phone;
      const addressChanged = this.address !== customer.address;
      return firstNameChanged || lastNameChanged || emailChanged || phoneChanged || addressChanged;
    },
    emailRule() {
      return v => emailRegex.test( String(v || '').toLowerCase() ) || 'Invalid email';
    },
    phoneRule() {
      return v => phoneRegex.test(v || '') || 'Invalid phone number';
    },
  },

  methods: {
    save(close){
      this.$store.commit('updateCustomer', { index: this.index, customer: this.$data });
      this.$store.commit('setCurrentChanged', true);

      close();
    },
    getAddressData(addressComponents, { formatted_address }) {
      this.address = formatted_address;
      this.geocode_address = addressComponents;
    },
    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },
};
</script>
